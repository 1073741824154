<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div
            class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
        >
          <h3>Vouchers</h3>
          <div class="justify-content-between">
              <router-link
                  :to="{ name: 'my_prescriptions' }"
                  class="ps-btn btn-sm"
                  v-if="auth.isAuthenticated"
              ><i class="icon-list"></i> Orders
              </router-link>
              &nbsp;&nbsp;
              <router-link
                  :to="{ name: 'merchant_create_voucher' }"
                  v-if="auth.isAuthenticated"
                  class="ps-btn btn-sm"
              ><i class="icon-plus"></i> Create Vouchers</router-link
              >
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered">
            <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="voucher in vouchers" :key="voucher.id">
                <td>{{ voucher.created_at | formatDate }}</td>
                <td>{{ voucher.name }}</td>
                <td>{{ voucher.amount | toCurrency }}</td>
                <td>{{ voucher.status }}</td>
                <td>
                  <router-link
                      :to="{ name: 'merchant_edit_voucher', params: {id: voucher.id}  }"
                      v-if="auth.isAuthenticated"
                  ><i class="icon-pencil"></i> </router-link>

                  &nbsp;&nbsp;

                  <router-link
                      :to="{ name: 'merchant_edit_voucher', params: {id: voucher.id}  }"
                      v-if="auth.isAuthenticated"
                  ><i class="icon-eye"></i> </router-link>


                  &nbsp;&nbsp;

                  <router-link
                      :to="{ name: 'merchant_voucher_orders', params: {id: voucher.id} }"
                      v-if="auth.isAuthenticated"
                  ><i class="icon-list"></i> Orders</router-link>

                </td>

                <!-- OTP Modal -->
                <div
                    class="modal fade"
                    :id="'SendOTP' + voucher.id"
                    tabindex="-1"
                    :aria-labelledby="'exampleModalLabel' + voucher.id"
                    aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5
                            class="modal-title"
                            :id="'exampleModalLabel' + voucher.id"
                        >
                          Send OTP
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <p>Send OTP to verify the user</p>
                        <form>
                          <div class="form-group">
                            <label for="voucher_number"
                            >Phone Number of Receiver</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                id="voucher_number"
                                placeholder="+"
                                :value="voucher.voucher_number"
                            />
                          </div>
                          <div class="form-group" :if="sentOTP">
                            <label for="phone"
                            >Voucher One Time Password</label
                            >
                            <input
                                type="phone"
                                class="form-control"
                                id="phone"
                                placeholder="+263777123123"
                                v-model="otp"
                            />
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button
                            class="btn btn-success"
                            v-on:click="sendOTP(voucher.voucher_number)"
                        >
                          Send OTP
                        </button>
                        <button
                            :if="sentOTP"
                            class="btn btn-info"
                            v-on:click="verifyOTP(voucher.voucher_number)"
                        >
                          Verify OTP
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger"
                            data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </tr>
            </tbody>
            <tfoot>
            <tr v-if="(total === 0) && !isLoading">
              <td colspan="7" class="text-center m-4 text-danger p-4">
                No vouchers available {{ isLoading }} -- {{ meta.total }} ---- {{ (!meta || meta.total === 0) }} == {{ (!meta || meta.total === 0) && !isLoading }}
              </td>
            </tr>
            <tr v-else-if="isLoading">
              <td colspan="7" class="text-center m-4 text-danger">
                Loading vouchers...
              </td>
            </tr>
            <tr v-else>
              <td class="text-right" colspan="7">
                <ul class="pagination text-right">
                  <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                    <a href="#">Prev Page<i class="icon-chevron-left"></i></a>
                  </li>
                  <li class="active">
                    <a href="#">Page {{ pageNumber }}</a>
                  </li>
                  <!--                      <li><a href="#">3</a></li>-->
                  <li v-on:click="goToNextPage()" v-if="hasNextPage">
                    <a href="#"
                    >Next Page<i class="icon-chevron-right"></i
                    ></a>
                  </li>
                </ul>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";
export default {
  data() {
    return {
      otp: "",
      voucherData: {},
      sentOTP: false,
      vouchers: [],
      meta: null,
      links: {},
      total: 0,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1,
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  mounted() {
    this.getVouchers();
  },
  methods: {
    getVouchers() {
      this.isLoading = true;
      axios.get("vouchers").then((response) => {
          this.vouchers = response.data.data;
          this.links = response.data.links;
          this.total = response.data.total;
          this.pageNumber = response.data.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    scrollTop() {
      let element = document.getElementsByClassName("table-responsive");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },

    goToNextPage: function() {
      this.url = this.nextPage;
      if (this.url) {
        this.getVouchers();
      }
      this.scrollTop();
    },

    goToPrevPage: function() {
      this.url = this.prevPage;
      if (this.url) {
        this.getVouchers();
      }
      this.scrollTop();
    },

    handleNextPage(links) {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false;
      }
    },

    handlePrevPage(links) {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false;
      }
    },

    sendOTP(voucher_number) {
      var data = {
        voucher_number: voucher_number,
      };
      axios
        .post("v1/merchant/initiate_redeem_voucher", data)
        .then((response) => {
          this.isLoading = false;
          this.vouchers = response.data.data;
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },

    verifyOTP(voucher_number) {
      var data = {
        voucher_number: voucher_number,
        otp: this.otp,
      };
      axios
        .post("v1/merchant/complete_redeem_voucher", data)
        .then((response) => {
          this.vouchers = response.data.data;
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>
